<template>
  <OForm :class="{submitting}" @submit="submit">
    <OFormHelp>
      We send a verification code to your email. Please enter the code below.
    </OFormHelp>
    <OFormInput
      v-if="!isLoggedIn"
      id="email"
      name="email"
      type="email"
      autocomplete="username"
      v-model="form.email"
      required
      placeholder="Enter your email"
    >
      E-mail
    </OFormInput>
    <OFormInput
      v-model="form.code"
      :disabled="submitting"
      name="code"
      placeholder="Enter the code from email"
      required
      @invalid="realValid=false"
      @valid="realValid=true"
    >
      Verification code:
    </OFormInput>
    <OFormMessage
      :type="error ? 'error' : message ? 'success' : 'info'"
      :message="error || message"></OFormMessage>
    <FormButtons>
      <OFormSubmitButton :disabled="submitting">
        Verify email
      </OFormSubmitButton>
      <OButton v-if="isLoggedIn" theme="outline-primary" :disabled="submitting" @click="resend">
        Resend email
      </OButton>
    </FormButtons>
  </OForm>
</template>

<script>
import apiForm from '../mixins/apiForm'
import OForm from '../objects/forms/OForm'
import OFormSubmitButton from '../objects/forms/OFormSubmitButton'
import FormButtons from '../objects/forms/FormButtons'
import OFormInput from '../objects/forms/OFormInput'
import OButton from '../objects/OButton'
import OFormMessage from '../objects/forms/OFormMessage'
import OFormHelp from '../objects/forms/OFormHelp'
import { mapActions } from 'vuex'

export default {
  name: 'CFormVerifyEmail',
  components: { OFormHelp, OFormMessage, OForm, FormButtons, OFormSubmitButton, OButton, OFormInput },
  mixins: [apiForm],
  data: function () {
    return {
      message: null,
      isLoggedIn: this.$store.getters.isLoggedIn,
      form: {
        email: this.$store.getters.getEmail,
        code: null
      },
      resendForm: {
        email: this.$store.getters.getEmail,
        password: this.$store.getters.getPassword
      }
    }
  },
  methods: {
    ...mapActions(['fetchInfo']),
    resend () {
      this.message = null
      this.$callApi(api => api.resendVerificationEmail(this.resendForm), {
        force: true,
        emitOnSuccess: false
      })
    },
    submit () {
      this.message = null
      this.$callApi(api => api.verifyEmail(this.form))
    }
  },
  mounted () {
    const params = new URLSearchParams(location.search)
    const user = params.get('user')
    if (user) {
      this.form.email = user
    } else if (!this.form.email) {
      this.$router.replace({
        name: 'login',
        query: { redirect: this.$router.currentRoute.fullPath }
      })
    }
    const code = params.get('code')
    if (code) {
      this.form.code = code
      setTimeout(this.submit)
    }
  },
  formSubmitted ({ message }) {
    this.message = message === 'login_failed'
      ? 'It appears you are not logged in. Logout and login and then try again.'
      : message
    this.fetchInfo()
  },
  formFailed () {
    this.fetchInfo()
  }
}
</script>
