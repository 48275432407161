<template>
  <LDefault>
    <OTitle>Verify email</OTitle>
    <CFormVerifyEmail @success="onSuccess"></CFormVerifyEmail>
  </LDefault>
</template>

<script>
import LDefault from '@/layouts/LDefault'
import CFormVerifyEmail from '@/forms/CFormVerifyEmail'
import OTitle from '../../objects/OTitle'

export default {
  name: 'PageVerify',
  components: { OTitle, LDefault, CFormVerifyEmail },
  methods: {
    onSuccess ({ message }) {
      this.$router.push({ name: 'home' })
    }
  }
}
</script>
